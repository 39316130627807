/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */
 #overlay{
  position:fixed;
  z-index:99999;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:rgba(0,0,0,0.7);
  transition: 1s 0.4s;
}
#progress{
  height:1px;
  background:var(--main);
  position:absolute;
  width:0;
  top:50%;
}
#progstat{
  font-size:0.7em;
  letter-spacing: 3px;
  position:absolute;
  top:50%;
  margin-top:-40px;
  width:100%;
  text-align:center;
  color:var(--main);
}

 body {
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
  color: gray
 }

:root {
  --main: #FFBD26;  
  --main-lighten: #fad377;
}

html {
  scroll-behavior: smooth;
 }

nav ul a,
nav .brand-logo {
  color: #444;
  height: 100%;
}

nav .brand-logo img {
  height: 150%;
  margin-top: -20px;
}

nav {
  position: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.6) !important;
  z-index: 99;
}

#nav-mobile {
  z-index: 9;
  height: 100vh;
}

.sidenav-overlay {
  z-index: 1;
}

p {
  line-height: 2rem;
}

.sidenav-trigger {
  color: var(--main);
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: 100vh;
  color: rgba(255, 255, 255, 0.9);
}
.parallax-container .section {
  width: 100%;
  height: 100%;
}

#mobile-logo {
  display: none;
}
#logo {
  display: block;
}

.parallax-container .parallax img {
  height: auto;
  background-repeat: no-repeat;
}

.parallax-container h1.header, .parallax-container h2 {
  color: var(--main-lighten) !important;
}

@media only screen and (max-width: 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }

  nav {
    height: 55px;
  }

  #mobile-logo {
    display: block;
  }

  #mobile-logo{
    height: 150%;
    margin-top: -18px;
  }
  #logo {
    display: none;
  }

  #index-banner .section {
    top: 10%;
  }

  .parallax-container .parallax img {
    height: 100%;
  }

  .parallax-container {
    height: 500px;
  }
}

@media only screen and (max-width: 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
}

#download-button, .custom-button {
  background-color: var(--main) !important;
  transition: 0.3s;
}

#download-button:after, .custom-button:after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

#download-button:hover:after, .custom-button:hover:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.draw-border {
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.draw-border::before,
.draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.draw-border::before {
  border-bottom-width: 2px;
  border-left-width: 2px;
}
.draw-border::after {
  border-top-width: 2px;
  border-right-width: 2px;

  /* default was 4px */
}
.draw-border:hover {
  /* color: #ffe593; */
  color: var(--main);
}
.draw-border:hover::before,
.draw-border:hover::after {
  border-color: var(--main);
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.center-parallax-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


.full-w-img {
  width: 100%;
}

.about-img {
  /* clip-path: polygon(10% 15%, 90% 0%, 100% 100%, 100% 100%, 100% 100%, 0% 95%, 0% 95%); */
  clip-path: polygon(0% 0%, 88% 0%, 100% 50%, 88% 100%, 0% 100%);
} 

.offert-row img{
  width: 100%;
}

.offert-row .description p {
  text-align: justify;
}  

@media screen and (min-width: 1600px) {
  .offert-row .description p {
    font-size: 1.8rem;
    line-height: 2.5rem !important;
  }  
}

@media screen and (min-width: 1220px) and (max-width: 1599px) {
  .offert-row .description p {
    font-size: 1.4rem;
    line-height: 2.4rem !important;
  }  
}

@media screen and (min-width: 900px) and (max-width: 1219px) {
  .offert-row .description p {
    font-size: 1.0rem;
    line-height: 1.8rem !important;
  }  
}

.faq-row { font-size: 1.2rem; border-left: solid 3px var(--main); padding-left: 2rem; margin-bottom: 2rem;}
.faq-row>.question { font-weight: bold; }
.faq-row>.answer { font-style: italic; }

footer.teal {
  background: var(--main-lighten) !important;
}

.contact-img {
  width: 100%;
}

.phone-numbers {
  color: var(--main);
  font-weight: bold;
  font-size: 1.5rem;
}

.switch label .lever {
  height: 16px;
  background-color: gainsboro;
}

.switch label .lever:before {
  background-color: var(--main-lighten) !important;
}

.switch label .lever:after {
  background-color: var(--main);
}

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: var(--main);
}

.switch label input[type=checkbox]:checked + .lever {
  background-color: gainsboro;
}

.switch label {
  font-size: 1.4rem;
}

#gallery .switch {
  text-align: center;
}

.center-btn {
  text-align: center;
}

.theme-btn {
  background-color: var(--main) !important;
}

.multiple-icons {
  display: flex;
  flex-wrap: nowrap;
  font-size: 2rem;
}

.multiple-icons a {
  font-size: 1.5rem;
  transition: all .8s;
}

.multiple-icons a:hover {
  color: var(--main);
}

.vh70 {
  height: 70vh;
}

#video video { margin: 5px; width: 100%;}

.contact-img {
  background-image: url(./img/contact.jpg);
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 45% 0px;
  margin: 10px;
  clip-path: polygon(0% 0%, 100% 0%, 99% 78%, 64% 78%, 23% 100%, 32% 78%, 0 78%);

}

.section p {
  text-align: justify;
}


.question::after {
  content: '?';
}

#faq h5.subheader {
  margin: 4rem 2.5rem;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: var(--main);
}

.section-questions-container {
  margin-bottom: 2rem;
}

.w100 {
  width: 100%;
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom-color: var(--main);
  box-shadow: 0 1px 0 0 var(--main);
}

input:not([type]):focus:not([readonly]) + label, input[type=text]:not(.browser-default):focus:not([readonly]) + label, input[type=password]:not(.browser-default):focus:not([readonly]) + label, input[type=email]:not(.browser-default):focus:not([readonly]) + label, input[type=url]:not(.browser-default):focus:not([readonly]) + label, input[type=time]:not(.browser-default):focus:not([readonly]) + label, input[type=date]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label, input[type=tel]:not(.browser-default):focus:not([readonly]) + label, input[type=number]:not(.browser-default):focus:not([readonly]) + label, input[type=search]:not(.browser-default):focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
  color: var(--main);
}

.dropdown-content li > a, .dropdown-content li > span {
  color: var(--main)
}

.display-name {
  text-align: center;
}

.hidden {
  display: none;
}

.offert-footer-buttons-container{
  display: flex;
  gap: 3px;
}

#gallery .center {
  margin: 0 auto;
  justify-content: center;
}


#contact select {
}

.container {
  scroll-margin-top: 50px;
}


footer.page-footer {
  background-color: var(--main);
}

.just-mail>a{
  font-size: 1.2rem;
  color: var(--main-lighten);
}

.error-toast {
  background-color: rgba(238, 78, 78, 0.8);
}

.success-toast {
  background-color: rgba(16, 95, 21, 0.8);
  
}

#why-photobox {
  padding-left: 2rem;
  text-align: justify;
}

#why-photobox li {
  margin-bottom: 1.2rem;
}

#photobox-description .header {
  margin-bottom: 1.5rem;
}

#photobox-description {
  margin: 2.5rem auto;
}

.modal-footer button {
  margin-right: 5px !important;
}

.searching {
  display: flex;
  align-items: center;
  justify-items: center;
}

.modal.bottom-sheet {
  top: 0;
  bottom: unset;
  z-index: 9999;
  max-height: unset;
}

.center-logo {
  text-align: center;
  margin: 0 auto;
  height: 150px;
}

.price-container {
  font-weight: bold;
  text-align: right;
  font-size: 1.5rem;
}

.offert-row div:first-child{
  margin-bottom: 1rem;
}



.dlink {
  text-decoration: none !important;
  color: #03a9f3 !important;
}

.data-table-extensions-filter {
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 3px 5px;
  width: 70%;
}

.first {
  padding-right: 10px !important;
}

.filter-text {
  border: none !important;
  width: 80%;
}

/* select {
  display: block;
} */

.data-table-extensions-filter {
  display: flex;
  align-items: center;
}

.filter-text {
  height: auto !important;
  margin: unset !important;
}

.table-actions {
	display: flex;
	gap: 5px;
  align-items: center;
}

.green-custom {
background: rgb(76, 193, 76) !important;
}

.red-custom {
background: rgb(217, 97, 97) !important;
}

.orange-custom {
  background: rgb(245, 138, 62) !important;
  }

  
.rdt_Pagination {
  width: 470px !important;
}

#book-modal.modal {
  display: unset;
}

#book-modal {
	overflow-y: hidden;
	max-height: 100vh; 
	transition-property: all;
	transition-duration: .5s;
}

#book-modal.closed {
	max-height: 0;
}


/* ------- COOKIE ----- */

.fa-cookie-bite {
  font-size: 8rem;
  color: gold;
  position: absolute;
  z-index: -1;
  opacity: .5;
}

#cookie-overflow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(92, 81, 19, 0.772);
  z-index: 99999;
}

#cookie-consest {
    max-width: 600px;
    min-width: 400px;
    border: solid 2px gold;
    border-radius: 10px;
    font-family: Halvetica, sans-serif;
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    transform: translate(-50%, -50%);
}

.clear { 
  clear: both
}

.info {
  text-align: justify;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 1.5rem;
}

.switches-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
}

.cookie-switch-container {
  border: solid 1px gold;
  padding: 2rem;
  display: flex;
  transition: .5s;
  cursor:pointer;
  justify-content: space-around;
  align-items: center;
}

.cookie-switch-container:hover {
  background: #ffd70085;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: space-around;
}

.buttons-container > button {
   width: 40%;
   padding: .6rem;
   flex-grow: 1;
   height: unset;
}

@media screen and (max-width: 1166px) {
  .cookie-switch-container, .buttons-container > button  { 
    width: 100%;
  }
}

button.bordered {
  background: white;
  color: black;
  border: solid 2px gold;
}

button.gold:not(.bordered) {
  background: gold;
  color: black;
}

button:hover {
  background: #ffd70085 !important;
}


.cookie-content>div {
  padding: 1.5rem;
  transition: .5s;
  text-align: justify;
}



/* ------- COOKIE END ------ */