:root {
    --main: #FFBD26;  
    --main-lighten: #fad377;
  }
          * {
              margin: 0;
              padding: 0;
          }
             @import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Montserrat:wght@300&family=Oswald:wght@300&family=PT+Sans:wght@700&family=Poppins:wght@300&family=Roboto&family=Rubik&family=Ubuntu&display=swap');
  
  main#choice-page {
    overflow: hidden;
      display: flex;
      flex-wrap: wrap;
  }
  
  #choice-page .choice {
      transition: .5s;
      height: 50vh;
      width: 100%;
      position: relative;
  }
  
  #choice-page .choice img {
      object-position: center center;
      height: 100%;
      width: 100%;
  }
  
  #choice-page .caption {
      position: absolute;
      font-family: 'Montserrat', sans-serif;
      border: solid 3px rgb(62, 62, 62);
      padding: 2rem;
      font-size: 2rem;
      font-weight: normal;
      display: block;
      z-index: 999;
      color: rgb(62, 62, 62);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      transition: .8s;
  }
  
  @media screen and (min-width: 1000px) {
    #choice-page .choice {
      flex: 1 0 50%;
      height: 100vh;
  }
  
  #choice-page .choice img {
      width: 100%;
      object-position: center center;
  }
  
  #choice-page .caption {
      font-size: 3rem;
  }
  
  }
  
  #choice-page .choice:hover {
      cursor: pointer;
  }
  
  #choice-page .choice img {
      filter: grayscale(1) brightness(30%);
      object-fit: cover;
      transition: .8s;
  
  }
  
  #choice-page .choice:hover img {
      filter: grayscale(0) brightness(100%);
  }
  
  #choice-page .choice:hover .caption {
      color: gold;
      text-shadow: 0px 0px 10px gold;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px) !important;
      border-color: gold;
  }
  
  
  #choice-page .draw-border {
    transition: color 0.25s 0.0833333333s;
    position: relative;
  }
  #choice-page .draw-border::before,
  #choice-page .draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    filter: blur(1px);
  }
  #choice-page .draw-border::before {
    border-bottom-width: 8px;
    border-left-width: 8px;
  }
  #choice-page .draw-border::after {
    border-top-width: 8px;
    border-right-width: 8px;
  
    /* default was 4px */
  }
  #choice-page .draw-border:hover {
    /* color: #ffe593; */
    color: var(--main);
  }
  #choice-page .draw-border:hover::before,
  #choice-page .draw-border:hover::after {
    border-color: var(--main);
    transition: border-color 0s, width 0.25s, height 0.5s;
    width: 100%;
    height: 100%;
  }
  #choice-page .draw-border:hover::before {
    transition-delay: 0s, 0s, 0.5s;
  }
  #choice-page .draw-border:hover::after {
    transition-delay: 0s, 0.5s, 0s;
  }